<template>
  <div class="leads-list">
    <h2>Leads Generados</h2>
    <table>
      <thead>
        <tr>
          <th>Nombre del Negocio</th>
          <th>Tipo de Negocio</th>
          <th>Descripción</th>
          <th>Teléfono</th>
          <th>Email</th>
          <th>Fecha de Creación</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="lead in leads" :key="lead._id">
          <td>{{ lead.nombre_negocio }}</td>
          <td>{{ lead.tipo_negocio }}</td>
          <td>{{ lead.descripcion }}</td>
          <td>{{ lead.datos_contacto ? lead.datos_contacto.telefono : 'N/A' }}</td>
          <td>{{ lead.datos_contacto ? lead.datos_contacto.email : 'N/A' }}</td>
          <td>{{ new Date(lead.fecha_creacion).toLocaleDateString() }}</td>
        </tr>
        <tr v-if="leads.length === 0">
          <td colspan="6" class="no-leads">No se encontraron leads.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      leads: []
    };
  },
  methods: {
    async fetchLeads() {
      try {
        const response = await axios.get('https://brainstream.pro/pruebas/leads'); // Ajusta la URL según tu configuración
        this.leads = response.data;
      } catch (error) {
        console.error('Error al obtener los leads:', error);
      }
    }
  },
  mounted() {
    this.fetchLeads();
  }
}
</script>

<style scoped>
.leads-list {
  padding: 20px;
  background-color: #f9f9f9; /* Color de fondo suave */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #333; /* Color del texto del encabezado */
  font-size: 24px;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  background-color: #4CAF50; /* Color de fondo del encabezado */
  color: white; /* Color del texto del encabezado */
  padding: 12px 15px;
}

td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2; /* Color de fondo alternado para filas */
}

tbody tr:hover {
  background-color: #d9fdd9; /* Color de fondo al pasar el ratón */
}

.no-leads {
  text-align: center;
  font-style: italic;
  color: #777; /* Color del texto si no hay leads */
  padding: 20px;
}

@media (max-width: 600px) {
  table {
    font-size: 14px; /* Tamaño de fuente en pantallas pequeñas */
  }

  th, td {
    padding: 8px; /* Espaciado reducido en pantallas pequeñas */
  }
}
</style>
