<template>
  <div class="min-h-screen bg-gray-100">
    <NavBar />
    <div class="container mx-auto px-4 py-6">
      <SearchBar @search="filterConversations" />
      <ConversationList :users="filteredUsers" />
    </div>
  </div>
</template>

<script>
import NavBar from './NavBar.vue';
import SearchBar from './SearchBar.vue';
import ConversationList from './ConversationList.vue';
import axios from 'axios';

export default {
  components: {
    NavBar,
    SearchBar,
    ConversationList,
  },
  data() {
    return {
      users: [],
      filteredUsers: [],
    };
  },
  created() {
    this.fetchConversations();
  },
  methods: {
    fetchConversations() {
      axios
        .get('https://brainstream.pro/prueba/users_with_conversations')
        .then((response) => {
          this.users = response.data;
          this.filteredUsers = this.users;
        })
        .catch((error) => {
          console.error('Error al obtener las conversaciones:', error);
        });
    },
    filterConversations(query) {
      // Filtro por nombre o número de teléfono
      if (query) {
        this.filteredUsers = this.users.filter((user) => {
          const nameMatch = user.name && user.name.toLowerCase().includes(query.toLowerCase());
          const phoneMatch = user.phone_number && user.phone_number.includes(query);
          return nameMatch || phoneMatch;
        });
      } else {
        this.filteredUsers = this.users;
      }
    },
  },
};
</script>

<style scoped>
/* Estilos adicionales si los necesitas */
</style>
