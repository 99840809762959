<template>
  <div>
    <div v-if="users.length === 0" class="text-center text-gray-600">
      No hay conversaciones disponibles.
    </div>
    <div v-else>
      <div
        v-for="(user, index) in users"
        :key="index"
        class="mb-8 bg-white shadow rounded-lg p-4"
      >
        <!-- Mostrar nombre y teléfono -->
        <h3 class="text-xl font-semibold">{{ user.name || user.phone_number }}</h3>
        <p class="text-sm text-gray-600">{{ user.email || 'Sin email' }}</p>
        <p class="text-sm text-gray-600">{{ user.phone_number || 'Sin teléfono' }}</p>

        <!-- Mostrar últimos 5 mensajes si hay threads -->
        <div v-if="user.threads && user.threads.length > 0">
          <h4 class="text-lg font-medium text-gray-700 mt-4">Últimos 5 Mensajes</h4>
          
          <!-- Verificar y mostrar los mensajes -->
          <ul>
            <li
              v-for="(message, idx) in getLastFiveMessages(user)"
              :key="idx"
              class="text-sm text-gray-800 mt-2"
            >
              {{ message.message_content || 'Sin contenido' }} ({{ formatDate(message.created_at) }})
            </li>
          </ul>
        </div>
        <div v-else>
          <p class="text-sm text-gray-600">No hay mensajes disponibles.</p>
        </div>

        <!-- Botón de ver más -->
        <button
          @click="viewAllMessages(user)"
          class="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Ver más
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getLastFiveMessages(user) {
      // Asegurar que existan threads y mensajes
      if (!user.threads || user.threads.length === 0) {
        return [];
      }

      let messages = [];
      user.threads.forEach((thread) => {
        if (thread.messages && thread.messages.length) {
          messages = messages.concat(thread.messages);
        }
      });

      // Retornar los últimos 5 mensajes
      return messages.slice(-5);
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
    viewAllMessages(user) {
      console.log('Ver todos los mensajes del usuario', user);
      // Aquí podrías implementar una redirección o la apertura de un modal con toda la conversación
    },
  },
};
</script>
