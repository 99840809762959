<template>
  <nav class="bg-white shadow">
    <div class="container mx-auto px-4 py-4 flex items-center justify-between">
      <div class="text-2xl font-semibold text-gray-800">
        Conversations Dashboard
      </div>
      <!-- Aquí puedes agregar más elementos de navegación si es necesario -->
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
/* Estilos específicos para la navegación si es necesario */
</style>
