<template>
  <div id="app-container">
    <!-- Flechita flotante -->
    <div id="toggle-button" @click="toggleSidebar" :class="{ 'open': isSidebarOpen }">
      <span>{{ isSidebarOpen ? '<' : '>' }}</span>
    </div>

    <!-- Barra Flotante Izquierda -->
    <transition name="slide-sidebar">
      <div v-if="isSidebarOpen" id="floating-sidebar">
        <!-- Botones de Asistentes -->
        <div id="sidebar-content">
          <button
            v-for="button in buttons"
            :key="button.id"
            @click="selectAssistant(button.assistant)"
            class="sidebar-button"
            :class="{ active: currentAssistant === button.assistant }"
          >
            {{ button.label }}
          </button>
        </div>
      </div>
    </transition>

    <!-- Nombre del Asistente Seleccionado -->
    <div id="active-button-title">{{ activeAssistantLabel }}</div>

    <!-- Área de Contenido Principal -->
    <div id="main-content" ref="chatContainer" :class="{ 'sidebar-open': isSidebarOpen }">
      <!-- Mostrando mensajes actuales -->
      <div v-for="(message, index) in currentMessages" :key="index" :class="['chat-message', message.sender]">
        <div v-if="message.sender === 'bot'" class="bot-message">
          <p>{{ message.content }}</p>
        </div>
        <div v-else class="user-message">
          <p>{{ message.content }}</p>
        </div>
      </div>

      <!-- Animación de carga mientras se espera la respuesta del bot -->
      <div v-if="loading" class="loading-spinner"></div>

      <!-- Cuadro de Texto y Botón de Envío -->
      <div id="chat-footer">
        <input 
          type="text" 
          v-model="userMessage" 
          placeholder="Escribe tu mensaje aquí..." 
          @keyup.enter="sendMessage"
          class="input-text"
        />
        <button @click="sendMessage" class="send-button">
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userMessage: '',               // Mensaje actual del usuario
      isSidebarOpen: false,           // Control de la barra lateral
      loading: false,                 // Estado de carga mientras el bot responde
      currentAssistant: '',           // El asistente seleccionado actualmente
      assistantsState: {},            // Estado para almacenar las conversaciones y IDs de hilos de los asistentes
      buttons: []                     // Botones para cada asistente, generados dinámicamente desde el backend
    };
  },
  computed: {
    currentMessages() {
      // Mostrar los mensajes del asistente seleccionado actualmente
      return this.assistantsState[this.currentAssistant]?.messages || [];
    },
    activeAssistantLabel() {
      // Mostrar la etiqueta del asistente actualmente seleccionado
      const activeButton = this.buttons.find(button => button.assistant === this.currentAssistant);
      return activeButton ? activeButton.label : '';
    }
  },
  created() {
    // Cargar los asistentes cuando el componente se monta
    this.fetchAssistants();
  },
  methods: {
    async fetchAssistants() {
      try {
        const response = await fetch('/GPTeams/assistants'); // Solicita los asistentes del backend
        const data = await response.json();

        // Crear los botones para cada asistente sin modificar sus IDs
        this.buttons = data.assistants.map((assistant, index) => ({
          id: index + 1,
          label: assistant.name,       // Nombre visible del asistente
          assistant: assistant.key     // ID del asistente (exacto, sin modificaciones)
        }));

        // Inicializar el estado de cada asistente (hilo y mensajes vacíos)
        data.assistants.forEach(assistant => {
          this.assistantsState[assistant.key] = { threadId: null, messages: [] };
        });

        // Seleccionar el primer asistente por defecto si existe
        if (this.buttons.length > 0) {
          this.currentAssistant = this.buttons[0].assistant;
        }
      } catch (error) {
        console.error('Error al cargar los asistentes:', error);
        this.showError('No se pudieron cargar los asistentes.');
      }
    },
    toggleSidebar() {
      // Alternar la barra lateral (abierta/cerrada)
      this.isSidebarOpen = !this.isSidebarOpen;
      this.adjustChatPosition();
    },
    selectAssistant(assistant) {
      // Cambiar al asistente seleccionado sin modificar el ID
      this.currentAssistant = assistant;
      this.adjustChatPosition();
    },
    adjustChatPosition() {
      // Ajustar el scroll para mostrar el último mensaje
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        if (chatContainer) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }
      });
    },
    sendMessage() {
      // Enviar el mensaje al asistente seleccionado
      if (this.userMessage.trim() !== '') {
        const assistant = this.currentAssistant;
        const assistantData = this.assistantsState[assistant];

        // Añadir inmediatamente el mensaje del usuario
        this.appendMessage('user', this.userMessage, assistant);

        // Mostrar animación de carga
        this.loading = true;

        const requestData = {
          prompt: this.userMessage,
          thread_id: assistantData.threadId // Si ya hay un hilo, se usa el ID actual
        };

        // Enviar la solicitud al backend utilizando el ID exacto del asistente
        fetch(`/GPTeams/chat/${assistant}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData)
        })
        .then(response => response.json())
        .then(data => {
          if (data.response) {
            // Si hay respuesta del asistente, añadirla y actualizar el thread_id
            this.appendMessage('bot', data.response, assistant);
            assistantData.threadId = data.thread_id;  // Actualizar el ID del hilo
          } else {
            this.appendMessage('bot', 'Error: No se pudo procesar tu solicitud.', assistant);
          }
          this.loading = false;  // Ocultar la animación de carga
        })
        .catch(error => {
          console.error('Error al enviar el mensaje:', error);
          this.appendMessage('bot', 'Error al comunicarse con el servidor.', assistant);
          this.loading = false;  // Ocultar la animación en caso de error
        });

        this.userMessage = '';  // Limpiar el campo de entrada después de enviar
      }
    },
    appendMessage(sender, content, assistant) {
      // Añadir un nuevo mensaje al historial del asistente seleccionado
      this.assistantsState[assistant].messages.push({ sender, content });
      this.adjustChatPosition();  // Asegurar que el último mensaje sea visible
    },
    showError(message) {
      // Mostrar un error al usuario
      alert(message);
    }
  }
};
</script>



<style scoped>
/* Contenedor Principal */
#app-container {
  position: relative;
  height: 100vh;
  background-color: #121212;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center; /* Centrar el contenido horizontalmente */
}

/* Flechita flotante */
#toggle-button {
  position: fixed;
  top: 50%;
  left: 0;
  width: 40px;
  height: 40px;
  background-color: #6C63FF;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s ease;
  transform: translate(-50%, -50%);
}

#toggle-button.open {
  left: 200px; /* Ajuste según el ancho de la barra lateral */
}

#toggle-button span {
  font-size: 18px;
  transition: all 0.3s ease;
}

/* Barra Flotante Izquierda */
#floating-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px; /* Ancho de la barra lateral */
  background-color: #1E1E1E;
  z-index: 1000;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Transición para la barra lateral */
.slide-sidebar-enter-active, .slide-sidebar-leave-active {
  transition: all 0.3s ease;
}
.slide-sidebar-enter, .slide-sidebar-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

/* Título dinámico del asistente activo */
#active-button-title {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 18px; /* Tamaño más pequeño para evitar zoom */
  font-weight: bold;
  opacity: 0.8;
  background: linear-gradient(90deg, white, #6C63FF, #121212);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  color: transparent;
  animation: liquidGradient 5s ease infinite, fadeInRight 0.8s ease forwards;
}

/* Animación del degradado en movimiento */
@keyframes liquidGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Contenido de la Barra (Botones) */
#sidebar-content {
  padding: 10px 0;
}

.sidebar-button {
  display: block;
  width: calc(100% - 20px); /* Asegura que los botones ocupen todo el ancho disponible menos el padding */
  margin: 8px auto;
  padding: 10px;
  font-size: 14px;
  background: #2D2D2D;
  color: white;
  text-align: left;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  position: relative; /* Para el indicador visual activo */
  transition: background 0.3s;
}

.sidebar-button.active {
  background: #6C63FF;
}

.sidebar-button:hover {
  background: #8A83FF;
}

/* Área de Contenido Principal */
#main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  margin-left: 60px; /* Ajuste para la barra lateral cerrada */
  max-width: 800px; /* Limitar el ancho del contenido */
}

.sidebar-open #main-content {
  margin-left: 200px; /* Ajuste según el ancho de la barra lateral */
}

/* Mensajes del Usuario */
.user-message {
  background-color: #6C63FF;
  color: white;
  padding: 15px;
  border-radius: 15px 15px 5px 15px; /* Redondeo inferior derecho */
  align-self: flex-end;
  max-width: 75%;
  word-wrap: break-word;
  margin-bottom: 10px;
  font-size: 14px; /* Asegurar que el texto sea pequeño */
}

/* Mensajes del Bot */
.bot-message {
  background-color: #444;
  color: white;
  padding: 15px;
  border-radius: 15px 15px 15px 5px; /* Redondeo inferior izquierdo */
  align-self: flex-start;
  max-width: 75%;
  word-wrap: break-word;
  margin-bottom: 10px;
  border: 1px solid #555;
  font-size: 14px; /* Asegurar que el texto sea pequeño */
}

/* Animación de espera mientras el sistema responde */
.loading-spinner {
  align-self: flex-start;
  width: 20px; /* Más pequeña */
  height: 20px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #6C63FF;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
  margin-left: 10px; /* Alinea con los mensajes del bot */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Ajuste dinámico para el cuadro de texto y botón de envío */
#chat-footer {
  position: fixed;
  bottom: 0;
  left: 0; /* El cuadro de texto siempre comienza pegado al lado izquierdo */
  width: 100%; /* Cubriendo todo el ancho */
  background-color: #1E1E1E;
  display: flex;
  padding: 10px 20px; /* Se ajusta el padding para ambos lados */
  box-sizing: border-box; /* Para que padding y ancho se comporten bien juntos */
  transition: left 0.3s ease, width 0.3s ease;
}

.sidebar-open #chat-footer {
  left: 200px; /* Ajuste según el ancho de la barra lateral desde el lado izquierdo */
  width: calc(100% - 200px); /* Ajuste dinámico */
}

.input-text {
  flex: 1;
  padding: 10px;
  background-color: #2D2D2D;
  border: 1px solid #444;
  border-radius: 5px;
  color: white;
  font-size: 16px; /* Tamaño de fuente ajustado a 14px para evitar zoom en iOS */
  margin-right: 10px;
}

.send-button {
  background-color: #6C63FF;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  line-height: 1;
}

.send-button:hover {
  background-color: #8A83FF;
}

@media (max-width: 768px) {
  .sidebar-open #main-content {
    margin-left: 180px;
  }
  .sidebar-open #chat-footer {
    left: 180px;
    width: calc(100% - 180px); /* Se ajusta también en pantallas pequeñas */
  }
}
</style>
