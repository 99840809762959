<template>
  <div class="container">
    <header>
      <h1>Visionario 365</h1>
      <h2>Conquista tus Sueños</h2>
      <p class="subtitle">Transforma tus metas en realidad, paso a paso</p>
    </header>

    <div class="quote">
      "El éxito no es un accidente. Es trabajo duro, perseverancia, aprendizaje, estudio, sacrificio y, sobre todo, amor por lo que estás haciendo." - Pelé
    </div>

    <!-- Filtro de objetivos -->
    <div class="objectives-filter">
      <select v-model="filter" @change="filterObjectives">
        <option value="all">Todos los objetivos</option>
        <option value="thisYear">Objetivos de Este Año</option>
        <option value="2025">Objetivos 2025</option>
        <option value="5years">Objetivos a 5 Años</option>
        <option value="10years">Objetivos a 10 Años</option>
      </select>
    </div>

    <!-- Lista de objetivos -->
    <div class="objectives" id="objectivesContainer">
      <div v-for="objective in filteredObjectives" :key="objective.id" class="objective-card">
        <img :src="objective.imageUrl" alt="Imagen del objetivo" class="objective-image">
        <div class="objective-content">
          <h3 class="objective-title">{{ objective.title }}</h3>
          <p class="objective-category">{{ objective.category }} - {{ getTimeframeText(objective.timeframe) }}</p>
          <div class="timer">{{ objective.deadline }}</div>
          <h4>Tareas a Realizar:</h4>
          <ul class="task-list">
            <li v-for="(task, index) in objective.tasks" :key="index" class="task-item">
              <input type="checkbox" v-model="task.completed">
              <span>{{ task.text }}</span>
            </li>
          </ul>
          <button @click="openModal(objective.id)">Editar</button>
          <button @click="deleteObjective(objective.id)">Eliminar</button>
        </div>
      </div>
    </div>

    <!-- Botón para añadir nuevo objetivo -->
    <button class="add-objective" @click="openModal()">Añadir nuevo objetivo</button>

    <!-- Modal para agregar o editar un objetivo -->
    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>{{ editingObjective ? 'Editar objetivo' : 'Añadir nuevo objetivo' }}</h2>
        <form @submit.prevent="saveObjective">
          <div class="form-group">
            <label for="title">Título del objetivo:</label>
            <input type="text" id="title" v-model="form.title" required>
          </div>
          <div class="form-group">
            <label for="category">Tipo de objetivo:</label>
            <select id="category" v-model="form.category">
              <option value="Personal">Personal</option>
              <option value="Profesional">Profesional</option>
            </select>
          </div>
          <div class="form-group">
            <label for="timeframe">Plazo:</label>
            <select id="timeframe" v-model="form.timeframe">
              <option value="thisYear">Este año</option>
              <option value="2025">2025</option>
              <option value="5years">Próximos 5 años</option>
              <option value="10years">Próximos 10 años</option>
            </select>
          </div>
          <div class="form-group">
            <label for="deadline">Fecha límite:</label>
            <input type="date" id="deadline" v-model="form.deadline" required>
          </div>
          <div class="form-group">
            <label for="steps">Pasos a Seguir:</label>
            <div id="stepsContainer">
              <div v-for="(step, index) in form.steps" :key="index" class="step-item">
                <input type="text" v-model="step.text" class="step-input">
                <button type="button" @click="removeStep(index)">Eliminar paso</button>
              </div>
            </div>
            <button type="button" @click="generateSteps">Generar pasos</button>
          </div>
          <button type="submit" class="btn">Guardar objetivo</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      objectives: [], // Lista de objetivos
      filteredObjectives: [], // Lista filtrada de objetivos
      filter: 'all', // Filtro seleccionado
      showModal: false, // Control del modal
      form: {
        id: null,
        title: '',
        category: 'Personal',
        timeframe: 'thisYear',
        deadline: '',
        steps: []
      },
      editingObjective: false // Indica si se está editando un objetivo
    };
  },
  methods: {
    openModal(id = null) {
      this.showModal = true;
      if (id) {
        // Editar objetivo existente
        const objective = this.objectives.find(obj => obj.id === id);
        this.form = { ...objective };
        this.editingObjective = true;
      } else {
        // Añadir nuevo objetivo
        this.form = {
          id: null,
          title: '',
          category: 'Personal',
          timeframe: 'thisYear',
          deadline: '',
          steps: []
        };
        this.editingObjective = false;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    saveObjective() {
      if (this.editingObjective) {
        // Editar objetivo existente
        const index = this.objectives.findIndex(obj => obj.id === this.form.id);
        this.objectives.splice(index, 1, this.form);
      } else {
        // Añadir nuevo objetivo
        this.form.id = Date.now(); // Generar un ID único
        this.objectives.push({ ...this.form });
      }
      this.filterObjectives(); // Asegurarse de actualizar la lista filtrada
      this.closeModal();
    },
    deleteObjective(id) {
      this.objectives = this.objectives.filter(obj => obj.id !== id);
      this.filterObjectives(); // Actualizar la lista filtrada
    },
    filterObjectives() {
      if (this.filter === 'all') {
        this.filteredObjectives = this.objectives;
      } else {
        this.filteredObjectives = this.objectives.filter(obj => obj.timeframe === this.filter);
      }
    },
    generateSteps() {
      if (this.form.title) {
        fetch('/cosmicmind/generate-steps', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ title: this.form.title, timeframe: this.form.timeframe })
        })
          .then(response => response.json())
          .then(data => {
            this.form.steps = data.steps.map(step => ({ text: step }));
          })
          .catch(err => {
            console.error('Error al generar los pasos:', err);
            alert('Error al generar los pasos. Inténtalo de nuevo.');
          });
      } else {
        alert("Por favor, introduce un título para el objetivo.");
      }
    },
    removeStep(index) {
      this.form.steps.splice(index, 1);
    }
  },
  mounted() {
    // Cargar objetivos iniciales (si los hay)
    this.filteredObjectives = this.objectives;
  }
};
</script>

<style scoped>
/* Estilos para el componente */

body {
  background-color: #ffffff; /* Fondo completamente blanco */
  margin: 0;
  padding: 0;
  font-family: 'Roboto', Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  color: #333;
  padding: 60px 0;
  background: #ffffff;
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
}

h1 {
  font-size: 3em;
  margin-bottom: 10px;
}

h2 {
  font-size: 2em;
}

.subtitle {
  font-size: 1.4em;
}

.objectives-filter select {
  padding: 12px 20px;
  font-size: 1.1em;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.objectives {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.objective-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  margin: 20px 10px;
  width: calc(50% - 20px); /* Dos tarjetas por fila */
  overflow: hidden;
}

.objective-title {
  font-size: 1.4em;
  margin-bottom: 15px;
  color: #3498db;
}

.objective-category {
  font-size: 1em;
  margin-bottom: 15px;
  font-weight: bold;
}

.add-objective {
  background: #3498db;
  color: white;
  padding: 15px 30px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.1em;
  margin-top: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 600px;
}

.step-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.step-input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
}
</style>
