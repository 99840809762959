<template>
  <div class="mb-6">
    <input
      v-model="searchQuery"
      @input="onSearch"
      type="text"
      placeholder="Buscar por nombre o número de teléfono..."
      class="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    onSearch() {
      // Emitir el evento con el query
      this.$emit('search', this.searchQuery);
    },
  },
};
</script>


<style scoped>
/* Estilos específicos si es necesario */
</style>
